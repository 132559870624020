import axios from "../../utils/axios";

//
export function botList(params) {
    return axios({
        url: "/admin/bot/list",
        method: "get",
        params: params
    });
}

//
export function botAll(params) {
    return axios({
        url: "/admin/bot/all",
        method: "get",
        params: params
    });
}

//
export function botSave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/bot/add"
            : "/admin/bot/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function botDelete(data) {
    return axios({
        url: "/admin/bot/delete",
        method: "post",
        data: data
    });
}
