<template>

  <div>
    <el-form :inline="true" :model="query" class="query-form" size="mini">

      <el-form-item class="query-form-item" label="机器人名称">
        <el-input v-model="query.name" placeholder="机器人名称"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button-group>
          <el-button type="primary" icon="el-icon-refresh" @click="onReset"></el-button>
          <el-button type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button type="primary" @click.native="handleForm(null,null)">新增</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-scrollbar class="table-scrollbar" wrap-style="overflow-x: hidden;">
      <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%;">
        <el-table-column
            label="ID"
            prop="id"
            fixed>
        </el-table-column>
        <el-table-column
            label="机器人名称"
            prop="name">
        </el-table-column>
        <el-table-column
            label="机器人Token"
            prop="token">
        </el-table-column>
        <el-table-column
            label="管理的聊天ID"
            prop="chat_ids">
        </el-table-column>
        <el-table-column
            label="发送消息的间隔(毫秒)"
            prop="send_space">
        </el-table-column>
        <el-table-column
            label="管理员ChatID"
            prop="admin_chat_id">
        </el-table-column>
        <el-table-column
            label="管理员备注"
            prop="admin_name">
        </el-table-column>
        <el-table-column
            label="绑定管理员的密钥"
            prop="key">
        </el-table-column>
        <el-table-column
            width="180"
            label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            label="更新时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modified_time | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click.native="handleForm(scope.$index, scope.row)">编辑
            </el-button>
            <el-button type="text" size="small" @click.native="handleDel(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <el-pagination
        :page-size="query.limit"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total">
    </el-pagination>

    <!--表单-->
    <el-dialog
        :close-on-click-modal="false"
        :title="formMap[formName]"
        :visible.sync="formVisible"
        :before-close="hideForm"
        width="85%"
        top="5vh">
      <el-form :model="formData" :rules="formRules" ref="dataForm">
        <el-form-item label="机器人名称" prop="name">
          <el-input v-model="formData.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="机器人Token" prop="token">
          <el-input v-model="formData.token" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="管理的聊天ID" prop="chat_ids">
          <br>
          <el-checkbox-group v-model="formData.chat_ids">
            <el-checkbox v-for="item in groupList" :key="item.chat_id" :label="item.chat_id">{{item.title}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="发送消息的间隔(毫秒)" prop="send_space">
          <el-input v-model.number="formData.send_space" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="管理员ChatID" prop="admin_chat_id">
          <el-input v-model.number="formData.admin_chat_id" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="管理员备注" prop="admin_name">
          <el-input v-model="formData.admin_name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="绑定管理员的密钥" prop="key">
          <el-input v-model="formData.key" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="hideForm">取消</el-button>
        <el-button type="primary" @click.native="formSubmit()" :loading="formLoading">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {botDelete, botList, botSave} from "../../api/bot/bot";
import {groupAll} from "../../api/group/group";

const formJson = {
  id: "",
  name: "",
  token: "",
  chat_ids: [],
  send_space: 200,
  admin_chat_id: "",
  admin_name: "",
  key: "",
};
export default {
  computed: {
  },
  data() {
    return {
      query: {
        name: "",
        page: 1,
        limit: 20,
      },
      list: [],
      total: 0,
      loading: true,
      index: null,
      formName: null,
      formMap: {
        add: "新增",
        edit: "编辑"
      },
      formLoading: false,
      formVisible: false,
      formData: formJson,
      formRules: {
        name: [
          {required: true, message: "请输入名称", trigger: "blur"}
        ],
        chat_ids: [
          {required: true, message: "请输入管理的聊天ID", trigger: "blur"}
        ],
        token: [
          {required: true, message: "请输入Token", trigger: "blur"}
        ]
      },
      deleteLoading: false,

      groupList: [],
      groupMap: new Map(),
    };
  },
  methods: {
    onReset() {
      this.$router.push({
        path: ""
      });
      this.query = {
        name: "",
        page: 1,
        limit: 20,
      };
      this.getList();
    },
    onSubmit() {
      this.$router.push({
        path: "",
        query: this.query
      });
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      botList(this.query)
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
            }
            this.list = response.data.list || [];
            this.total = response.data.total || 0;
          })
          .catch(() => {
            this.loading = false;
            this.list = [];
            this.total = 0;
          });
    },
    // 隐藏表单
    hideForm() {
      // 更改值
      this.formVisible = !this.formVisible;
      return true;
    },
    // 刷新表单
    resetForm() {
      if (this.$refs["dataForm"]) {
        // 清空验证信息表单
        this.$refs["dataForm"].clearValidate();
        // 刷新表单
        this.$refs["dataForm"].resetFields();
      }
    },
    // 显示表单
    handleForm(index, row) {
      this.formVisible = true;
      this.formData = JSON.parse(JSON.stringify(formJson));
      if (row !== null) {
        this.formData = Object.assign({}, row);
      }
      this.formData.chat_ids = this.formData.chat_ids || []
      this.formName = "add";
      if (index !== null) {
        this.index = index;
        this.formName = "edit";
      }
    },
    formSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.formLoading = true;
          let data = Object.assign({}, this.formData);
          botSave(data, this.formName).then(response => {
            this.formLoading = false;
            if (response.code) {
              this.$message.error(response.message);
              return false;
            }
            if (this.formName === "add") {
              // 向头部添加数据
              if (response.data && response.data.id) {
                data.id = response.data.id;
                data.create_time = new Date()
                data.modified_time = new Date()
                this.list.unshift(data);
              }
            } else {
              data.modified_time = new Date()
              this.list.splice(this.index, 1, data);
            }
            this.$message.success("操作成功");
            // 刷新表单
            this.resetForm();
            this.hideForm()
          });
        }
      });
    },
    // 删除
    handleDel(index, row) {
      if (row.id) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning"
        })
            .then(() => {
              let para = {id: row.id};
              this.deleteLoading = true;
              botDelete(para)
                  .then(response => {
                    this.deleteLoading = false;
                    if (response.code) {
                      this.$message.error(response.message);
                      return false;
                    }
                    this.$message.success("删除成功");
                    // 刷新数据
                    this.list.splice(index, 1);
                  })
                  .catch(() => {
                    this.deleteLoading = false;
                  });
            })
            .catch(() => {
              this.$message.error("取消删除");
            });
      }
    },

    getGroupList() {
      groupAll()
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
              return
            }
            this.groupList = response.data.list || [];
            this.groupMap = new Map()
            for (let item of this.groupList) {
              this.groupMap.set(item.id, item.name)
            }
          })
          .catch(() => {
          });
    },
  },
  filters: {
  },
  mounted() {
  },
  created() {
    // 将参数拷贝进查询对象
    let query = this.$route.query;
    this.query = Object.assign(this.query, query);
    this.query.limit = parseInt(this.query.limit);
    // 加载表格数据
    this.getList();
    this.getGroupList();
  }
};
</script>

<style lang="less" scoped>
</style>
